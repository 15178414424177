
/* .scrollPanel {
        max-height: 70vh;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-right: calc(var(--plasma-grid-margin) * (-1)) ;
        padding-right: calc(var(--plasma-grid-margin)) ;
} */

.scrollPanel {
    bottom: 0;
    left: 0px;
    padding-left: 30px;
    position: absolute;
    right: calc(var(--plasma-grid-margin) * (-1) + 30px);
    padding-right: calc(var(--plasma-grid-margin) - 20px) ;
    top: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    /* margin-right:  ; */
}

/* 
display: flex;
    flex-flow: column;
    height: 100%; */


html, body, #root, #root > div {
    min-height: 100%;
}
/* .MeetingPage, .MeetingPage > div {
    height: 100vh;
} */
.MeetingPage .rowContent {
    /* height: 100%; */
    /* overflow: auto; */
}
/* .MeetingPage .rowContent > .ant-col,
.MeetingPage .rowContent > .ant-col > .ant-row,
.MeetingPage .rowContent > .ant-col > .ant-row > .ant-col {
    height: 100%;
} */
.h100 { height: 100%; }
.vh100 { height: 100vh; }

.TimeProgress .ant-progress-inner {
    display: flex;
    justify-content: flex-end;
    /* justify-content: center; */
}

.FixCellListItem > div, 
.FixCellListItem > div > div { width: 100%; }
.FixCheckbox { width: 100% !important; flex-wrap: nowrap !important; }
.FixCheckbox > div { width: 20px; }
.FixCheckbox > span { width: 90%; }

