
.TextRecList {
    line-height: 2.1;
    padding-right: 240px;
    /* padding-right: calc(240px - var(--plasma-grid-margin)*(-1) + 20px); */
    /* margin-left: 220px; */

    /* max-height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden; */

    /* width: auto; */
    /* margin-right: calc(var(--plasma-grid-margin)*(-1) - 20px); */
}

.TextRecList .AboutKI-wrapper { 
    float: right;
    display: inline-block;
    width: 200px;
    clear: right;
    margin-right: -220px;
    line-height: 1;
}
.AboutKI-wrapper .AboutKI-btn { 
    /* background: yellow; */
    background: linear-gradient(30deg, #edff00, #f5c105);
    box-shadow: 0px 5px 10px #bda8126b;
    color: black;
    padding: 5px 10px;
    line-height: 1;
    height: auto;
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    margin: 1px;
}
/* .ant-modal-root, */
/* .ant-modal-root * {  */
/* .ant-modal { 
    transition: all 0.4s ease-in-out;
} */

.ki_example { 
    font-weight: bold; 
    /* text-decoration: underline;  */
    background: yellow;
    color: black;
}

.rl-fragment {    
    padding: 7px;
    border-radius: 6px;
    /* margin: 2px; */
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    cursor:pointer;
} 
.rl-fragment:hover {    
    background: rgba(255,255,255, 0.2   );
    /* background: #12A55788; */
    /* background: var(--plasma-colors-button-secondary); */
    /* background: #00000077; */
    /* background: var(--plasma-colors-button-accent); */
}
.rl-fragment-recognized {
    
}
.rl-fragment-with-ki { 
    /* background: var(--plasma-colors-button-secondary); */
    /* background: rgba(222, 204, 14, 0.2); */
    /* background: rgb(104 100 50 / 72%); */
    border-bottom: 3px solid rgba(222, 204, 14, 0.7);
}
.rl-fragment-with-ki:hover {    
    /* background: rgba(255,255,255, 0.2); */
    background: rgba(222, 204, 14, 0.7);
    /* background: rgba(222, 204, 14, 0.7); */
    
    /* background: var(--plasma-colors-button-accent); */
}
.rl-fragment-command {
    /* text-decoration: underline; */
    /* background-color: rgb(113, 51, 171); */
    background-color: #108ee9aa;
    /* border-bottom: 3px solid #108ee9; */
}
.rl-fragment-command:hover {    
    /* background: rgba(255,255,255, 0.2); */
    background:  #108ee9ee;
}
.rl-fragment-playing, .rl-fragment-playing:hover {
    background-color: white;
    color: black;
}
    

.ReportRecList {
    /* line-height: 2.1; */
    padding-right: calc(150px - var(--plasma-grid-margin)*(-1) + 20px);

    /* max-height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;

    margin-right: calc(var(--plasma-grid-margin)*(-1) - 20px); */
}

.ReportRecList-item {
    margin-bottom: 10px;
}
.ReportRecList-item .rl-fragment-command {
    background-color: transparent;
}
.ReportRecList-item .rl-fragment:hover {    
    /* background: rgba(255,255,255, 0.2); */
    background: var(--plasma-colors-button-accent);
}
.ReportRecList-item .rl-fragment-playing, .ReportRecList-item .rl-fragment-playing:hover {
    background-color: white;
    color: black;
}

.ReportRecList .AboutKI-wrapper { 
    display: none;
}